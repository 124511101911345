/**
 *  A uppy extension for generate a pdf thumnail.
 */
import { useRef } from 'react'
import { Document, Page } from 'react-pdf'
import Styles from '../pages/styles/pdfViewer.module.scss'

const PdfThumbnail = ({ pdfFile, filePreviewRef, uppy }) => {
  const canvasRef = useRef(null)

  const captureThumbnail = () => {
    if (canvasRef.current) {
      const canvas = canvasRef.current
      const imageURL = canvas.toDataURL('image/jpeg')
      // console.log('imageURL', imageURL)
      /** Convert thumbnail to blob format */
      fetch(imageURL)
        .then((response) => response.blob())
        .then((thumbnailBlob) => {
          const splitedName = pdfFile.name.split('.')
          const shortName = splitedName[0]
          /** Add thumbnail to uppy. */
          uppy.addFile({
            name: `thumbnail-${shortName}`, // file name
            type: 'image/jpeg', // file type
            data: thumbnailBlob, // file blob
            meta: {
              isThumbnail: true
            }
          })
          // Save thumbnail url
          filePreviewRef.current = thumbnailBlob
        })
        .catch((err) => console.log('Error generating pdf thumbnail:', err))
    }
  }

  return (
    <div>
      {/** Render first pdf page */}
      <Document className={Styles.hide} file={pdfFile.data}>
        <Page
          pageNumber={1}
          width={512}
          onRenderSuccess={captureThumbnail}
          canvasRef={canvasRef}
        />
      </Document>
    </div>
  )
}
export default PdfThumbnail
